<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <form id="UpdateOffice" @submit.prevent="UpdateOffice" >
        <CCardHeader>
            <CCol col="6" class="styleHeader"> {{ $t('message.updateOffice') }}</CCol>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="6">
              <CInput :label=" $t('message.name')"
                id="name"
                v-model="office.name"
                class="required"
                pattern="\D.{2,}"
                oninvalid="setCustomValidity('The name must consist of letters and is not acceptable to consist of numbers')"
                :placeholder=" $t('message.name')"
                :invalidFeedback="errors.name"
                required
              />
            </CCol>
            <CCol sm="6">
              <CInput
               :label=" $t('message.nameAr')"
                id="name_ar"
                type="text"
                v-model="office.name_ar"
                class="required"
                :placeholder=" $t('message.nameAr')"
                :invalidFeedback="errors.name_ar"
                required
              />
            </CCol>
          </CRow>

        </CCardBody>
        <CCardFooter>
          <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save') }}
          </CButton>
              &emsp;
          <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }}
          </CButton>
        </CCardFooter>
      </form>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'EditOffice',
  data () {
    return {
      office: [],
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}offices/${this.$route.params.id}`)
      .then((response) => {
        this.office = response.data.data
        this.selectedUsers = this.office.users
      })
  },
  methods: {
    async UpdateOffice () {
      const formData = new FormData()
      formData.append('name', this.office.name)
      formData.append('name_ar', this.office.name_ar)
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, `${this.$hostUrl}offices/${this.$route.params.id}`, '/offices')
    },
    goBack () {
      this.citiesOpened ? this.$router.go(-1) : this.$router.push({ path: '/offices' })
    }
  }
}
</script>
